hr {
    height: 1px;
    width: 550px;
    margin-top: 12px;
    border-left: 0px;
    border-color: #ffffff transparent transparent transparent;
}

.modal-body {
  padding-top: 0px !important;
}

.preview-headertext-div + p{
  color: black !important;
  font-size: 1rem;
}

.preview-body-div {
  text-align: left;
}

.preview-body-div h3 {
  text-align: center;
}

.tooltip-div {
  position: relative;
  padding-top: 4px;
}

.tooltip-div .tooltip-text {
  visibility: hidden;
  width: 222px;
  background-color: #404040;
  color: #fff;
  border-radius: 6px;
  font-size: 8px;
  text-align: left;
  padding: 6px 6px;
  position: absolute;
  z-index: 1;
  left: 18px;
}

.tooltip-div .tooltip-text::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #404040 transparent transparent;
}

.tooltiptext-logo{
  top: -23px;
}

.tooltiptext-header{
  top: -10px;
}

.tooltiptext-disclosure{
  top: -10px;
}

.tooltip-logo:hover .tooltiptext-logo {
  visibility: visible;
}

.tooltip-header:hover .tooltiptext-header {
  visibility: visible;
}

.tooltip-disclosure:hover .tooltiptext-disclosure {
  visibility: visible;
}

.btn {
  padding: 7px 10px;
  cursor: pointer;
  border: 0px;
  font-family: 'Poppins';
}

.save-update {
  color: white;
  background-color: #6351ed;
}

.save-update:disabled {
  cursor: not-allowed;
  color: #4d4b4b;
  background-color: #c4c4c4;
}

.uploaded-image {
  max-width: 98px;
  max-height: 42.5px;
}


.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 13px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 33px;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: -10px;
    bottom: -4px;
    border: 1px solid #6351ed;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #6351ed;
    /* background-color: ; */
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #6351ed;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .rdw-editor-main {
    padding: 0px 13px;
  }

  .italic-div p {
    font-family: 'Times New Roman', Times, serif !important;
  }

  .error-div {
    color: #6351ed;
    font-size: 0.7rem;
    text-align: center;
    margin-top: 0.3rem;
  }

  .disclosure-editor{
    background-color: #eee;
    border-radius: 4px;
    border: 1px solid #a1a1a1;
    margin-bottom: 15px;
    width: 100%;
    height: 80px;
    color: black;
    font-size: 10px;
  }

 
  
  .new_tooltip .newtooltiptext {
    visibility: hidden;
    width: 130px;
    background-color: black;
    color: #fff;
    text-align: center;
    justify-content: center;
    border-radius: 6px;
    padding: 5px 4px; 
    position: absolute;
    bottom: 80%;
    left: 50%;
    margin-left: -16px;
  }
  
  .new_tooltip .newtooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  
  .new_tooltip:hover .newtooltiptext {
    visibility: visible;
  }

  .new_tooltip .atstooltiptext {
    visibility: hidden;
    width: 130px;
    background-color: black;
    color: #fff;
    text-align: center;
    justify-content: center;
    border-radius: 6px;
    padding: 5px 4px; 
    position: absolute;
    bottom: 70%;
    left: -18%;
    margin-left: -16px;
  }
  
  .new_tooltip .atstooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  
  .new_tooltip:hover .atstooltiptext {
    visibility: visible;
  }