.select-type {
    margin-top: 75px;
    font-size: large;
    font-weight: bold;
    color: #000000;
    margin-bottom: 31px;
}

.email-address {
    margin-bottom: 30px;
    margin-top: 66px;
    font-size: 16px;
    font-weight: bold;
    color: #6351ed;

}

.select-list {
    margin-bottom: 30px;
    margin-top: 75px;
    font-size: 16px;
    font-weight: bold;
    color: #6351ed;
}

.upload-csv {
    margin-bottom: 30px;
    margin-top: 75px;
    font-size: 16px;
    font-weight: bold;
    color: #6351ed;
}

.upload-content .upload-text {
    text-align: justify;
    margin-bottom: 20px;
}

.email-list-div {
    width: 100%;
}

.square-one {
    margin-right: 15px;
    width: 50px;
    height: 50px;
    background: lightgray;
    padding-right: 20px;
    padding-top: 12px;
    padding-left: 13px;
    border-radius: 6px;
    padding-bottom: 12px;
    font-weight: bold;
    color: #000000;
}

.square {
    margin-right: 15px;
    width: 50px;
    height: 50px;
    background: lightgray;
    padding-right: 15px;
    padding-top: 12px;
    padding-left: 15px;
    border-radius: 6px;
    padding-bottom: 12px;
    font-weight: bold;
    color: #000000;
}

.upload-content {
    text-align: center;
    margin-left: 100px;
}

.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: -12px;
}

.email-list-div ul {
    list-style: decimal;
    margin-left: 0px;
}

.invite-div {
    width: 100%;
}

.invite-div .input-fields {
    border: 1.2px solid #cacaca;
    border-radius: 8px;
    padding: 8px;
    background-color: #f0f0f0;
    border-radius: 0px;
    margin: 0px 6px;
    margin-bottom: 10px;
}

.line-div {
    border: 1px solid #a1a1a1;
    border-bottom: 0px;
    width: 44%;
    height: 0;
}

.invite-div .invite-select {
    border: 1px solid #a1a1a1;
    margin-right: 20px;
    padding: 4px;
    width: 12rem;
    font-family: 'Poppins';
    font-size: inherit;
    margin-bottom: -25px;
    margin-left: 23px;
    background: lightgray;
    border-radius: 0px !important;
    color: darkgray;
}

.uploaded-file-div {
    font-size: 0.6rem;
    color: #6351ed;
    background-color: transparent;
    border: 0px;
    text-align: center;
    margin-top: 10px;
}

.invite-div .invite-next {
    font-family: Poppins;
    font-size: 13px;
    padding: 7px 20px;
    background-color: rgb(99, 81, 237);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(99, 81, 237);
    margin: 0px;
    cursor: pointer;
    height: auto;
    width: auto;
    margin-top: 20px;
    margin-bottom: 50px;
}

.invite-next:disabled {
    cursor: not-allowed;
    background-color: lightgray;
    color: #f0f0f0;
    border: none;
    margin-bottom: 50px;
}

.invite-div .fileUploaderButton {
    margin-bottom: 25px;
    margin-top: 25px;
}

.download-template {
    display: inline !important;
}


.content-row {
    text-align: left;
    padding-left: 18px;
    padding-right: 18px;
}

.content-sub-row {
    margin-left: 50px;
    text-align: left;
    margin-bottom: 10px;
}

.content-row-header {
    font-size: 18px;
    padding-bottom: 20px;
    padding-top: 20px;
    font-weight: bold;
    text-align: left;
    padding-left: 18px;

}

.content-row-head {
    text-align: left;
    padding-left: 18px;
    font-weight: bold;
}

.content-row-head-two {
    text-align: left;
    padding-left: 18px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 18px;
}

.content-row-img {
    width: 100%;
    text-align: center;
}

.content-img {
    margin-right: 15px;
    width: 20px;
    margin-top: 30px;
    margin-left: 15px;
    margin-bottom: 30px;
}

.invitation-header {
    color: #6351ed;
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 12px;
}

.dropdown-section {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
}

.clout-img {
    padding-right: 9px;
    padding-left: 9px;
    padding-top: 19px;
    padding-bottom: 19px;
    text-align: center;
    width: 550px;
    height: 150px;
}

.button-send-invitation {
    margin-bottom: 30px !important;
}

.flex-grid {
    display: flex;
    width: 75%;
    margin-left: 85px;
}

.flex-col {
    flex: 1;
}

.flex-col.img {
    margin-left: 26px;
}

.flex-col.text {
    margin-top: 45px;
}

.flex-col.btn {
    padding: 0px 0px !important;
}

.success-message {
    margin-top: 100px;
    color: #6351ed;
    padding-bottom: 50px;
}

.error-message {
    padding-bottom: 32px;
    color: #6351ed;
}

.invitation {
    margin-top: 105px;
}

.invite-div .invite-next-select{
    font-family: Poppins;
    font-size: 13px;
    padding: 7px 20px;
    background-color: rgb(99, 81, 237);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(99, 81, 237);
    margin: 0px;
    cursor: pointer;
    height: auto;
    width: auto;
    margin-top: 80px;
    margin-bottom: 50px;
}

.upload-img {
    margin-left: 26px;
    height: auto;
    width: auto;
    margin-top: -39px;
    margin-left: -14px;
    position: absolute;
}

::placeholder {
    color: gray;
  }

.invite-next-select:disabled {
    cursor: not-allowed;
    background-color: lightgray;
    color: #f0f0f0;
    border: none;
    margin-bottom: 50px;
}

.invite-has-error {
    text-align: left;
    margin-top: -6px;
    color: #6351ed;
    font-size: 12px;
    margin-bottom: 8px;
}

.btn-group button {
 /* Green background */
    border: 1px solid #6351ed ;
    padding: 10px 24px; 
    cursor: pointer; 
}