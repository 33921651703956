.main-container-div {
    width: 100%;
}

.flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.name-field {
    width: 40%;
    height: 100%;
}

.score-field {
    width: 60%;
    text-align: right;
}

.score-block {
    border: 0px;
    height: 23px;
    width: 23px;
    padding: 3px 10px 0 8px;
    margin-right: 5px;
    font-size: 0.7rem;
    color: white;
    font-weight: bold;
}

.pagination-buttons {
    color: white;
    background-color: #6351ed;
    padding: 7px 20px;
    border: 0px;
    font-size: 13px;
    margin-right: 4px;
    cursor: pointer;
    width: 106px;
    height: 34px;
    font-family: 'Poppins';
}

.pagination-buttons:disabled {
    background-color: #cacaca;
    color: #9c9c9c;
    cursor: not-allowed;
}

.filter-option {
    color: #949191;
    border-bottom: 1px solid #cacaca;
    text-align: center;
    font-size: 0.6rem;
    cursor: pointer;
    margin: 0px;
    padding: 6px 0;
    font-weight: bold;
}

.filter-option:hover,
.filter-option .activeFilter {
    color: #565353;
    background-color: #bebdc2;
}

.tooltip-div-name {
    position: relative;
    padding-top: 4px;
  }
  
  .tooltip-div-name .tooltip-text-name {
    visibility: hidden;
    width: auto;
    background-color: #404040;
    color: #fff;
    border-radius: 6px;
    font-size: 9px;
    text-align: left;
    padding: 4px 6px;
    position: absolute;
    z-index: 1;
    left: 86px;
    top: 28px;
  }
  
  .tooltip-div-name .tooltip-text-name::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
  }

  .tooltip-div-name .tooltip-text-date {
    visibility: hidden;
    width: auto;
    background-color: #404040;
    color: #fff;
    border-radius: 6px;
    font-size: 9px;
    text-align: left;
    padding: 4px 6px;
    position: absolute;
    z-index: 1;
    left: -2px;
    top: 18px;
    width: 90px;
    display: flex;
    justify-content: center;
  }
  
  .tooltip-div-name .tooltip-text-date::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
  }
  
  .tooltiptext-name{
    top: -23px;
  }

  .tooltip-name:hover .tooltiptext-name {
    visibility: visible;
  }