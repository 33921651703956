.pagination-buttons_compliance {
    color: white;
    background-color: #7dc4d0;
    padding: 7px 20px;
    border: 0px;
    font-size: 13px;
    margin-right: 4px;
    cursor: pointer;
    width: 106px;
    height: 34px;
    font-family: 'Poppins';
}

.pagination-buttons_compliance:disabled {
    background-color: #d5d5d5;
    color: white;
    cursor: not-allowed;
}