.editor {
  background-color: #eee;
  border-radius: 4px;
  border: 1px solid #a1a1a1;
  margin-bottom: 15px;
 
}

.rdw-editor-toolbar {
  padding: 2px 5px 1px;
  background: #eee;
  border: none;
  justify-content: center;
  border-bottom: 1px solid #a1a1a1;
}

.rdw-inline-wrapper {
  margin-bottom: 0px;
}

.rdw-dropdown-wrapper {
  height: 17px;
  background: #eee;
  border: 1px solid #a1a1a1;
}

.rdw-fontsize-wrapper {
  margin-bottom: 0px;
}

.rdw-fontfamily-wrapper {
  margin-bottom: 0px;
}

.rdw-fontfamily-optionwrapper {
  width: 115px;
}

.public-DraftStyleDefault-block {
  margin: 0;
}

.rdw-option-wrapper {
  background: #eee;
  border: 1px solid #a1a1a1;
  height: 17px;
}

.rdw-option-wrapper img {
  height: 7px;
  opacity: 0.5;
}

.rdw-link-wrapper {
  margin-bottom: 0px;
}

.rdw-colorpicker-wrapper {
  margin-bottom: 0px;
}

.rdw-image-wrapper {
  margin-bottom: 0px;
}

.rdw-editor-main {
  height: 80px;
  max-height: 80px;
}

.colorPicker img{
  height: 7px;
}

.rdw-dropdown-selectedtext img {
  height: 7px;
  opacity: 0.5;
}

.rdw-dropdown-carettoopen {
  opacity: 0.5;
  border-top: 5px solid black;
}

.rdw-dropdown-carettoopen {
  font-size: 13px;
}

.rdw-dropdown-optionwrapper {
  overflow-y: auto;
}

.rdw-image-modal {
  top: 18px
}

.public-DraftEditorPlaceholder-inner {
  font-size: 12px;
}

.rdw-fontfamily-placeholder {
  font-size: 11px;
} 

.rdw-dropdownoption-default {
  font-size: 10px;
}

.rdw-text-align-dropdownOption {
  height: 24px;
}
  
.rdw-text-align-dropdownOption img {
  height: 10px;
}  

.rdw-colorpicker-modal {
  top: 18px;
}

.rdw-link-dropdownoption {
  height: 24px;
}

.rdw-link-dropdownoption img {
  height: 10px;
}

.rdw-image-modal-upload-option-label{
  overflow: hidden;
  width: 80%;
}

.rdw-link-modal-target-option {
  display: none;
}

.customizeEmailEditor {
  background-color: #e4e4e4;
}

.customizeEmailEditor .rdw-editor-main {
  height: 134px;
  max-height: 134px;
}

.customizeEmailEditor .rdw-dropdown-optionwrapper {
  max-height: 144px;
}

.customizeEmailEditor .rdw-dropdownoption-default {
  min-height: 24px;
  width: 100%;
}

.customizeEmailEditor .public-DraftEditorPlaceholder-inner {
  font-size: 13.33px;
  font-family: 'Poppins';
  color: #848484;
}
.customizeEmailEditor .rdw-editor-toolbar {
  background-color: #e4e4e4;
}

.settingsEditor .rdw-dropdown-optionwrapper {
  max-height: 88px;
}

.settingsEditor .rdw-dropdownoption-default {
  min-height: 23px;
}

.newsLetterEditor .rdw-dropdown-optionwrapper{
  max-height: 181px;
}

.rdw-center-aligned-block > * {
  text-align: center;
}

.rdw-left-aligned-block > * {
  text-align: left;
}

.rdw-right-aligned-block > * {
  text-align: right;
}



