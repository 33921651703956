.hidden-input {
    opacity: 0;
    position: absolute;
  }

.header-checkmark {
  margin-top: 2px;
}

.checkmark {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1px solid #767676;
  border-radius: 4px;
  position: relative;
}

.hidden-input:checked ~ .checkmark {
  background-color: #c95cfc;
}

.checkmark::after {
  content: '';
  position: absolute;
  width: 4px;
  height: 6px;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  top: 18%;
  left: 31%;
  transform: translate(-20%, -20%) rotateZ(40deg);
}
