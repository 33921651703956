.my-clients-share {
    color: white;
    background-color: #6351ed;
    padding: 8px;
    border: 0px;
    font-family: 'Poppins'; 
    margin-top: 15px;
    font-size: 0.9rem; 
    cursor: pointer
}

.my-clients-share:disabled {
    background-color: #c5c1c1;
    color: #757474;
    cursor: not-allowed;
}