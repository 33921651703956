.hor-sep {
    height: 1px;
    width: 700px;
    margin-top: 12px;
    border-left: 0px;
    border-color: #ffffff transparent transparent transparent;
}

.tooltip-div-email {
  position: relative;
  padding-top: 4px;
}

.tooltip-div-email .tooltip-text-email {
  visibility: hidden;
  width: 222px;
  background-color: #404040;
  color: #fff;
  border-radius: 6px;
  font-size: 8px;
  text-align: left;
  padding: 6px 6px;
  position: absolute;
  z-index: 1;
  left: 18px;
}

.tooltip-div-email .tooltip-text-email::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #404040 transparent transparent;
}

.tooltiptext-logo-email{
  top: -23px;
}

.tooltiptext-header-email{
  top: -2px;
}

.tooltiptext-disclosure-email{
  top: -2px;
}

.tooltip-logo-email:hover .tooltiptext-logo-email {
  visibility: visible;
}

.tooltip-header-email:hover .tooltiptext-header-email {
  visibility: visible;
}

.tooltip-disclosure-email:hover .tooltiptext-disclosure-email {
  visibility: visible;
}

.lead-tab .lead-referral {
  top: 30px;
  left: -80px  !important;
  width: 130px !important;
  text-align: center !important;
  font-size: 10px !important;
  color: #cccccc !important;
}

.lead-tab .lead-referral-inside {
  top: 20px;
  left: 31px !important;
  width: 130px !important;
  text-align: center !important;
  font-size: 10px !important;
  color: #cccccc !important;
}

.lead-tab .lead-referral:after, .lead-tab .lead-referral-inside:after {
  content: none !important;
}

.btn-email {
    padding: 7px 10px;
    cursor: pointer;
    border: 0px;
    font-family: 'Poppins';
    display: block;
    margin: auto;
  }
  
  .save-update {
    color: white;
    background-color: #6351ed;
  }
  
  .save-update:disabled {
    cursor: not-allowed;
    color: #4d4b4b;
    background-color: #c4c4c4;
  }
  
  .uploaded-image-email {
    max-width: 98px;
    max-height: 42.5px;
  }
