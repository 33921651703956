.pageNumbers{
    list-style: none;
    display: flex;
    margin: 5px 0px 5px;
    padding: 0px;
}

.pageNumbers li {
    padding: 10px;
    border: 1px solid white;
    cursor: pointer;
    font-size: 14px;
    color: #848484;
  }

  
  .pageNumbers li.active {
    background-color: white;
    color: black;
    padding: 10px;
    font-size: 14px;
    align-items: center;
    display: flex;
  }
