@import url(https://rsms.me/inter/inter.css);
body {
  background-color: #f5f5f5;
  margin: 0px;
}


body.campaign {
  height: 100%;
}

@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/themes-icon.eot');
  src: url('/assets/fonts/themes-icon.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/themes-icon.woff') format('woff'),
    url('/assets/fonts/themes-icon.ttf') format('truetype'),
    url('/assets/fonts/themes-icon.svg#themes-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-bold';
  src: url("/assets/fonts/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-semibold';
  src: url("/assets/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-medium';
  src: url("/assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

html, 
body {
  font-family: 'Poppins' !important;
}


body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
textarea {
  font-family: 'Poppins';
  color: #6351ed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

::placeholder {
  color: #6351ed;
  font-family: 'Poppins';
}
::-webkit-scrollbar {
  width: 10px;
}
*:focus {
  outline: 0px;
}
::-webkit-scrollbar-thumb {
  background: #49484a !important;
  border-radius: 10px !important;
}
::-webkit-scrollbar-track {
  border-radius: 10px !important;
  background: #bcb8be !important;
}

.ReactModal__Overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  overflow-y: auto;
}

.slick-arrow::before {
  font-family: 'fideate-v2' !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-feature-settings: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-arrow.slick-disabled {
  opacity: 0.5;
}

.slick-arrow.slick-prev::before {
  content: 'U' !important;
}
.slick-arrow.slick-next::before {
  content: 'V' !important;
}
.slick-slide img {
  display: inline !important;
}

.Popover-tipShape {
  fill: #fff;
  stroke: #cacaca;
}

.article-style {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.select-type {
    margin-top: 75px;
    font-size: large;
    font-weight: bold;
    color: #000000;
    margin-bottom: 31px;
}

.email-address {
    margin-bottom: 30px;
    margin-top: 66px;
    font-size: 16px;
    font-weight: bold;
    color: #6351ed;

}

.select-list {
    margin-bottom: 30px;
    margin-top: 75px;
    font-size: 16px;
    font-weight: bold;
    color: #6351ed;
}

.upload-csv {
    margin-bottom: 30px;
    margin-top: 75px;
    font-size: 16px;
    font-weight: bold;
    color: #6351ed;
}

.upload-content .upload-text {
    text-align: justify;
    margin-bottom: 20px;
}

.email-list-div {
    width: 100%;
}

.square-one {
    margin-right: 15px;
    width: 50px;
    height: 50px;
    background: lightgray;
    padding-right: 20px;
    padding-top: 12px;
    padding-left: 13px;
    border-radius: 6px;
    padding-bottom: 12px;
    font-weight: bold;
    color: #000000;
}

.square {
    margin-right: 15px;
    width: 50px;
    height: 50px;
    background: lightgray;
    padding-right: 15px;
    padding-top: 12px;
    padding-left: 15px;
    border-radius: 6px;
    padding-bottom: 12px;
    font-weight: bold;
    color: #000000;
}

.upload-content {
    text-align: center;
    margin-left: 100px;
}

.flex-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: -12px;
}

.email-list-div ul {
    list-style: decimal;
    margin-left: 0px;
}

.invite-div {
    width: 100%;
}

.invite-div .input-fields {
    border: 1.2px solid #cacaca;
    border-radius: 8px;
    padding: 8px;
    background-color: #f0f0f0;
    border-radius: 0px;
    margin: 0px 6px;
    margin-bottom: 10px;
}

.line-div {
    border: 1px solid #a1a1a1;
    border-bottom: 0px;
    width: 44%;
    height: 0;
}

.invite-div .invite-select {
    border: 1px solid #a1a1a1;
    margin-right: 20px;
    padding: 4px;
    width: 12rem;
    font-family: 'Poppins';
    font-size: inherit;
    margin-bottom: -25px;
    margin-left: 23px;
    background: lightgray;
    border-radius: 0px !important;
    color: darkgray;
}

.uploaded-file-div {
    font-size: 0.6rem;
    color: #6351ed;
    background-color: transparent;
    border: 0px;
    text-align: center;
    margin-top: 10px;
}

.invite-div .invite-next {
    font-family: Poppins;
    font-size: 13px;
    padding: 7px 20px;
    background-color: rgb(99, 81, 237);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(99, 81, 237);
    margin: 0px;
    cursor: pointer;
    height: auto;
    width: auto;
    margin-top: 20px;
    margin-bottom: 50px;
}

.invite-next:disabled {
    cursor: not-allowed;
    background-color: lightgray;
    color: #f0f0f0;
    border: none;
    margin-bottom: 50px;
}

.invite-div .fileUploaderButton {
    margin-bottom: 25px;
    margin-top: 25px;
}

.download-template {
    display: inline !important;
}


.content-row {
    text-align: left;
    padding-left: 18px;
    padding-right: 18px;
}

.content-sub-row {
    margin-left: 50px;
    text-align: left;
    margin-bottom: 10px;
}

.content-row-header {
    font-size: 18px;
    padding-bottom: 20px;
    padding-top: 20px;
    font-weight: bold;
    text-align: left;
    padding-left: 18px;

}

.content-row-head {
    text-align: left;
    padding-left: 18px;
    font-weight: bold;
}

.content-row-head-two {
    text-align: left;
    padding-left: 18px;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 18px;
}

.content-row-img {
    width: 100%;
    text-align: center;
}

.content-img {
    margin-right: 15px;
    width: 20px;
    margin-top: 30px;
    margin-left: 15px;
    margin-bottom: 30px;
}

.invitation-header {
    color: #6351ed;
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 12px;
}

.dropdown-section {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
}

.clout-img {
    padding-right: 9px;
    padding-left: 9px;
    padding-top: 19px;
    padding-bottom: 19px;
    text-align: center;
    width: 550px;
    height: 150px;
}

.button-send-invitation {
    margin-bottom: 30px !important;
}

.flex-grid {
    display: flex;
    width: 75%;
    margin-left: 85px;
}

.flex-col {
    flex: 1 1;
}

.flex-col.img {
    margin-left: 26px;
}

.flex-col.text {
    margin-top: 45px;
}

.flex-col.btn {
    padding: 0px 0px !important;
}

.success-message {
    margin-top: 100px;
    color: #6351ed;
    padding-bottom: 50px;
}

.error-message {
    padding-bottom: 32px;
    color: #6351ed;
}

.invitation {
    margin-top: 105px;
}

.invite-div .invite-next-select{
    font-family: Poppins;
    font-size: 13px;
    padding: 7px 20px;
    background-color: rgb(99, 81, 237);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(99, 81, 237);
    margin: 0px;
    cursor: pointer;
    height: auto;
    width: auto;
    margin-top: 80px;
    margin-bottom: 50px;
}

.upload-img {
    margin-left: 26px;
    height: auto;
    width: auto;
    margin-top: -39px;
    margin-left: -14px;
    position: absolute;
}

::placeholder {
    color: gray;
  }

.invite-next-select:disabled {
    cursor: not-allowed;
    background-color: lightgray;
    color: #f0f0f0;
    border: none;
    margin-bottom: 50px;
}

.invite-has-error {
    text-align: left;
    margin-top: -6px;
    color: #6351ed;
    font-size: 12px;
    margin-bottom: 8px;
}

.btn-group button {
 /* Green background */
    border: 1px solid #6351ed ;
    padding: 10px 24px; 
    cursor: pointer; 
}
.Popover.Popover-below {
    top: 70%;
    left: 40%;
}
.hor-sep {
    height: 1px;
    width: 700px;
    margin-top: 12px;
    border-left: 0px;
    border-color: #ffffff transparent transparent transparent;
}

.tooltip-div-email {
  position: relative;
  padding-top: 4px;
}

.tooltip-div-email .tooltip-text-email {
  visibility: hidden;
  width: 222px;
  background-color: #404040;
  color: #fff;
  border-radius: 6px;
  font-size: 8px;
  text-align: left;
  padding: 6px 6px;
  position: absolute;
  z-index: 1;
  left: 18px;
}

.tooltip-div-email .tooltip-text-email::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #404040 transparent transparent;
}

.tooltiptext-logo-email{
  top: -23px;
}

.tooltiptext-header-email{
  top: -2px;
}

.tooltiptext-disclosure-email{
  top: -2px;
}

.tooltip-logo-email:hover .tooltiptext-logo-email {
  visibility: visible;
}

.tooltip-header-email:hover .tooltiptext-header-email {
  visibility: visible;
}

.tooltip-disclosure-email:hover .tooltiptext-disclosure-email {
  visibility: visible;
}

.lead-tab .lead-referral {
  top: 30px;
  left: -80px  !important;
  width: 130px !important;
  text-align: center !important;
  font-size: 10px !important;
  color: #cccccc !important;
}

.lead-tab .lead-referral-inside {
  top: 20px;
  left: 31px !important;
  width: 130px !important;
  text-align: center !important;
  font-size: 10px !important;
  color: #cccccc !important;
}

.lead-tab .lead-referral:after, .lead-tab .lead-referral-inside:after {
  content: none !important;
}

.btn-email {
    padding: 7px 10px;
    cursor: pointer;
    border: 0px;
    font-family: 'Poppins';
    display: block;
    margin: auto;
  }
  
  .save-update {
    color: white;
    background-color: #6351ed;
  }
  
  .save-update:disabled {
    cursor: not-allowed;
    color: #4d4b4b;
    background-color: #c4c4c4;
  }
  
  .uploaded-image-email {
    max-width: 98px;
    max-height: 42.5px;
  }

.my-clients-share {
    color: white;
    background-color: #6351ed;
    padding: 8px;
    border: 0px;
    font-family: 'Poppins'; 
    margin-top: 15px;
    font-size: 0.9rem; 
    cursor: pointer
}

.my-clients-share:disabled {
    background-color: #c5c1c1;
    color: #757474;
    cursor: not-allowed;
}
.tooltip-div-share-modal {
    position: relative;
    padding-top: 4px;
  }
  
  .tooltip-div-share-modal .tooltip-text-share-modal {
    visibility: hidden;
    width: 84px;
    background-color: #404040;
    color: #fff;
    border-radius: 6px;
    font-size: 8px;
    text-align: left;
    padding: 6px 6px;
    position: absolute;
    z-index: 1;
    left: 18px;
  }
  
  .tooltip-div-share-modal .tooltip-text-share-modal::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #404040 transparent transparent;
  }

  .tooltip-div-member:hover .tooltip-text-member {
    visibility: visible;
  }

  .tooltip-div-self:hover .tooltip-text-self {
    visibility: visible;
  }
.editor {
  background-color: #eee;
  border-radius: 4px;
  border: 1px solid #a1a1a1;
  margin-bottom: 15px;
 
}

.rdw-editor-toolbar {
  padding: 2px 5px 1px;
  background: #eee;
  border: none;
  justify-content: center;
  border-bottom: 1px solid #a1a1a1;
}

.rdw-inline-wrapper {
  margin-bottom: 0px;
}

.rdw-dropdown-wrapper {
  height: 17px;
  background: #eee;
  border: 1px solid #a1a1a1;
}

.rdw-fontsize-wrapper {
  margin-bottom: 0px;
}

.rdw-fontfamily-wrapper {
  margin-bottom: 0px;
}

.rdw-fontfamily-optionwrapper {
  width: 115px;
}

.public-DraftStyleDefault-block {
  margin: 0;
}

.rdw-option-wrapper {
  background: #eee;
  border: 1px solid #a1a1a1;
  height: 17px;
}

.rdw-option-wrapper img {
  height: 7px;
  opacity: 0.5;
}

.rdw-link-wrapper {
  margin-bottom: 0px;
}

.rdw-colorpicker-wrapper {
  margin-bottom: 0px;
}

.rdw-image-wrapper {
  margin-bottom: 0px;
}

.rdw-editor-main {
  height: 80px;
  max-height: 80px;
}

.colorPicker img{
  height: 7px;
}

.rdw-dropdown-selectedtext img {
  height: 7px;
  opacity: 0.5;
}

.rdw-dropdown-carettoopen {
  opacity: 0.5;
  border-top: 5px solid black;
}

.rdw-dropdown-carettoopen {
  font-size: 13px;
}

.rdw-dropdown-optionwrapper {
  overflow-y: auto;
}

.rdw-image-modal {
  top: 18px
}

.public-DraftEditorPlaceholder-inner {
  font-size: 12px;
}

.rdw-fontfamily-placeholder {
  font-size: 11px;
} 

.rdw-dropdownoption-default {
  font-size: 10px;
}

.rdw-text-align-dropdownOption {
  height: 24px;
}
  
.rdw-text-align-dropdownOption img {
  height: 10px;
}  

.rdw-colorpicker-modal {
  top: 18px;
}

.rdw-link-dropdownoption {
  height: 24px;
}

.rdw-link-dropdownoption img {
  height: 10px;
}

.rdw-image-modal-upload-option-label{
  overflow: hidden;
  width: 80%;
}

.rdw-link-modal-target-option {
  display: none;
}

.customizeEmailEditor {
  background-color: #e4e4e4;
}

.customizeEmailEditor .rdw-editor-main {
  height: 134px;
  max-height: 134px;
}

.customizeEmailEditor .rdw-dropdown-optionwrapper {
  max-height: 144px;
}

.customizeEmailEditor .rdw-dropdownoption-default {
  min-height: 24px;
  width: 100%;
}

.customizeEmailEditor .public-DraftEditorPlaceholder-inner {
  font-size: 13.33px;
  font-family: 'Poppins';
  color: #848484;
}
.customizeEmailEditor .rdw-editor-toolbar {
  background-color: #e4e4e4;
}

.settingsEditor .rdw-dropdown-optionwrapper {
  max-height: 88px;
}

.settingsEditor .rdw-dropdownoption-default {
  min-height: 23px;
}

.newsLetterEditor .rdw-dropdown-optionwrapper{
  max-height: 181px;
}

.rdw-center-aligned-block > * {
  text-align: center;
}

.rdw-left-aligned-block > * {
  text-align: left;
}

.rdw-right-aligned-block > * {
  text-align: right;
}




hr {
    height: 1px;
    width: 550px;
    margin-top: 12px;
    border-left: 0px;
    border-color: #ffffff transparent transparent transparent;
}

.modal-body {
  padding-top: 0px !important;
}

.preview-headertext-div + p{
  color: black !important;
  font-size: 1rem;
}

.preview-body-div {
  text-align: left;
}

.preview-body-div h3 {
  text-align: center;
}

.tooltip-div {
  position: relative;
  padding-top: 4px;
}

.tooltip-div .tooltip-text {
  visibility: hidden;
  width: 222px;
  background-color: #404040;
  color: #fff;
  border-radius: 6px;
  font-size: 8px;
  text-align: left;
  padding: 6px 6px;
  position: absolute;
  z-index: 1;
  left: 18px;
}

.tooltip-div .tooltip-text::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #404040 transparent transparent;
}

.tooltiptext-logo{
  top: -23px;
}

.tooltiptext-header{
  top: -10px;
}

.tooltiptext-disclosure{
  top: -10px;
}

.tooltip-logo:hover .tooltiptext-logo {
  visibility: visible;
}

.tooltip-header:hover .tooltiptext-header {
  visibility: visible;
}

.tooltip-disclosure:hover .tooltiptext-disclosure {
  visibility: visible;
}

.btn {
  padding: 7px 10px;
  cursor: pointer;
  border: 0px;
  font-family: 'Poppins';
}

.save-update {
  color: white;
  background-color: #6351ed;
}

.save-update:disabled {
  cursor: not-allowed;
  color: #4d4b4b;
  background-color: #c4c4c4;
}

.uploaded-image {
  max-width: 98px;
  max-height: 42.5px;
}


.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 13px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 33px;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: -10px;
    bottom: -4px;
    border: 1px solid #6351ed;
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #6351ed;
    /* background-color: ; */
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #6351ed;
  }
  
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .rdw-editor-main {
    padding: 0px 13px;
  }

  .italic-div p {
    font-family: 'Times New Roman', Times, serif !important;
  }

  .error-div {
    color: #6351ed;
    font-size: 0.7rem;
    text-align: center;
    margin-top: 0.3rem;
  }

  .disclosure-editor{
    background-color: #eee;
    border-radius: 4px;
    border: 1px solid #a1a1a1;
    margin-bottom: 15px;
    width: 100%;
    height: 80px;
    color: black;
    font-size: 10px;
  }

 
  
  .new_tooltip .newtooltiptext {
    visibility: hidden;
    width: 130px;
    background-color: black;
    color: #fff;
    text-align: center;
    justify-content: center;
    border-radius: 6px;
    padding: 5px 4px; 
    position: absolute;
    bottom: 80%;
    left: 50%;
    margin-left: -16px;
  }
  
  .new_tooltip .newtooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  
  .new_tooltip:hover .newtooltiptext {
    visibility: visible;
  }

  .new_tooltip .atstooltiptext {
    visibility: hidden;
    width: 130px;
    background-color: black;
    color: #fff;
    text-align: center;
    justify-content: center;
    border-radius: 6px;
    padding: 5px 4px; 
    position: absolute;
    bottom: 70%;
    left: -18%;
    margin-left: -16px;
  }
  
  .new_tooltip .atstooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  
  .new_tooltip:hover .atstooltiptext {
    visibility: visible;
  }
.analytics-filter {
    top: 52% !important;
    left: 40% !important;
}
.pagination-buttons_compliance {
    color: white;
    background-color: #7dc4d0;
    padding: 7px 20px;
    border: 0px;
    font-size: 13px;
    margin-right: 4px;
    cursor: pointer;
    width: 106px;
    height: 34px;
    font-family: 'Poppins';
}

.pagination-buttons_compliance:disabled {
    background-color: #d5d5d5;
    color: white;
    cursor: not-allowed;
}
.numbered-steps {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.step {
    background-color: #6351ed;
    color: white;
    border-radius: 12px;
    font-size: 9px;
    padding: 2px 5px 0px 4.5px;
}

.horizontal-step-connector {
    width: 5%;
    border: 0;
    height: 1.2px;
    background-color: #6351ed;
}
.connect-btn-sendgrid:disabled {
    background-color: #adadad;
    color: white;
    cursor: not-allowed;
}
.hidden-input-step2 {
    opacity: 0;
    position: absolute;
  }

  .checkmark-step2 {
    display: inline-block;
    width: 12px;
    height: 12px;
    border: 1px solid #767676;
    border-radius: 4px;
    position: relative;
  }

  .hidden-input-step2:checked ~ .checkmark-step2 {
    background-color: #c95cfc;
  }
  
  .checkmark-step2::after {
    content: '';
    position: absolute;
    width: 4px;
    height: 6px;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    top: 18%;
    left: 31%;
    transform: translate(-20%, -20%) rotateZ(40deg);
  }

  .records-table {
      width: 100%;
      margin: auto;
      margin-top: 10px;
      border: 1px solid #cacaca;
      border-radius: 8px;
      font-size: 11px;
      position: relative;
      max-height: 10rem;
      overflow-y: auto;
  }

  .records-table thead {
      background-color: #e0e0e0;
      line-height: 2rem;
  }

  .verify-btn:disabled {
    color: white;
    background-color: #adadad;
    cursor: not-allowed;
  }
.sendgrid-link {
    text-decoration: none;
    color: #a0a0a0;
    font-size: 9px;
}

.sendgrid-link:visited {
    color: #a0a0a0;
}
.connected-delete-btn {
    text-decoration: none;
    color: #a0a0a0;
    font-size: 9px;
}

.connected-delete-btn:visited {
    color: #a0a0a0;
}

.hidden-input {
    opacity: 0;
    position: absolute;
  }

.header-checkmark {
  margin-top: 2px;
}

.checkmark {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1px solid #767676;
  border-radius: 4px;
  position: relative;
}

.hidden-input:checked ~ .checkmark {
  background-color: #c95cfc;
}

.checkmark::after {
  content: '';
  position: absolute;
  width: 4px;
  height: 6px;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  top: 18%;
  left: 31%;
  transform: translate(-20%, -20%) rotateZ(40deg);
}

.flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vertical-separator {
    height: 10.1rem;
    width: 0;
    border: 0.1rem solid #e4e4e4;
    border-right: 0px;
    background-color: #e4e4e4;
    margin-top: 5px;
}

.client-icon-div {
    background-color: #6351ed;
    height: 5rem;
    position: absolute;
    width: 4.5rem;
    padding: 1.4rem;
    border-radius: 14px;
}
.client-icon-div.my-client-icon-div {  
    bottom: 2rem;
}

.client-icon-div.client-results-icon-div {
    bottom: 2rem;
}

.my-client-icon-wrapper {
    cursor: pointer;
}

.relative-div {
    height: 100%;
    position: relative;
}

.my-client-icon-wrapper.client-results h6 {
    position: absolute;
    top: -2.9rem;
}

.my-client-icon-wrapper.invite-clients h6 {
    position: absolute;
    top: -2.1rem;
}

.invite-pstv {
    width: 46%;
    height: 100%;
    margin: auto;
    margin-top: 8.3rem;
}

.invite-clients {
    left: 34%;
    width: 75px;
}

.pstv-score {
    left: 34%;
    width: 75px;
}

.invite {
    left: 78px;
    width: 35%;
}

.pstv {
    right: 83px;
    width: 39%;
}

.footer {
    margin-top: -1.5rem;
}

.footer img{
    left: -25%;
}

.footer h6 {
    color: #adadad;
}

.more-clients {
    position: absolute;
    font-size: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 200px;
    margin-left: -27%;
}

.more-clients p {
    color: #7dc5d0;
    margin: 0;
}
.main-container-div {
    width: 100%;
}

.flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.name-field {
    width: 40%;
    height: 100%;
}

.score-field {
    width: 60%;
    text-align: right;
}

.score-block {
    border: 0px;
    height: 23px;
    width: 23px;
    padding: 3px 10px 0 8px;
    margin-right: 5px;
    font-size: 0.7rem;
    color: white;
    font-weight: bold;
}

.pagination-buttons {
    color: white;
    background-color: #6351ed;
    padding: 7px 20px;
    border: 0px;
    font-size: 13px;
    margin-right: 4px;
    cursor: pointer;
    width: 106px;
    height: 34px;
    font-family: 'Poppins';
}

.pagination-buttons:disabled {
    background-color: #cacaca;
    color: #9c9c9c;
    cursor: not-allowed;
}

.filter-option {
    color: #949191;
    border-bottom: 1px solid #cacaca;
    text-align: center;
    font-size: 0.6rem;
    cursor: pointer;
    margin: 0px;
    padding: 6px 0;
    font-weight: bold;
}

.filter-option:hover,
.filter-option .activeFilter {
    color: #565353;
    background-color: #bebdc2;
}

.tooltip-div-name {
    position: relative;
    padding-top: 4px;
  }
  
  .tooltip-div-name .tooltip-text-name {
    visibility: hidden;
    width: auto;
    background-color: #404040;
    color: #fff;
    border-radius: 6px;
    font-size: 9px;
    text-align: left;
    padding: 4px 6px;
    position: absolute;
    z-index: 1;
    left: 86px;
    top: 28px;
  }
  
  .tooltip-div-name .tooltip-text-name::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
  }

  .tooltip-div-name .tooltip-text-date {
    visibility: hidden;
    width: auto;
    background-color: #404040;
    color: #fff;
    border-radius: 6px;
    font-size: 9px;
    text-align: left;
    padding: 4px 6px;
    position: absolute;
    z-index: 1;
    left: -2px;
    top: 18px;
    width: 90px;
    display: flex;
    justify-content: center;
  }
  
  .tooltip-div-name .tooltip-text-date::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
  }
  
  .tooltiptext-name{
    top: -23px;
  }

  .tooltip-name:hover .tooltiptext-name {
    visibility: visible;
  }
.contact_list_crm {
    list-style-type: none;
    overflow-y: auto;
    height: 128px;
    padding-left: 30px;
    padding-right: 6px;
    margin-top: 0px;
}

.contact_list_item_crm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: black;
    margin-bottom: 5px;
    padding: 6px 10px;
    width: 482px;
}

.crm_theme_icon {
    width: 25px;
    height: 25px;
    border: 0px;
    background-color: #6351ed;
    color: white;
    margin-right: 5px;
    border-radius: 3px;
}

.crm_icon_image {
    width: 25px;
    height: 25px;
    border-radius: 3px;
}

.crm_btns_div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 36%;
}
  
.tooltip-div-crm-topic .tooltiptext-crm-topic {
visibility: hidden;
width: auto;
background-color: black;
color: #fff;
text-align: center;
border-radius: 2px;
position: absolute;
right: -18px;
padding: 2px 4px;
font-size: 9px;
z-index: 1;
}

.tooltip-div-crm-topic:hover .tooltiptext-crm-topic {
visibility: visible;
}
/* @media screen and (min-width: 370px) and (max-width: 380px) {
    .react-pdf__Document canvas {
        margin-left: 33px
    }
} */

@media screen and (min-width: 410px) and (max-width: 415px) {
  .react-pdf__Document canvas {
    margin-left: -36px;
  }
}

@media screen and (width: 360px) {
  .react-pdf__Document canvas {
    margin-left: 13px;
  }
}

@media screen and (width: 320px) {
  .react-pdf__Document canvas {
    margin-left: 52px;
  }
}

.control-btns-container {
  padding: 15px 0px;
}

.react-pdf__Page__canvas {
  display: none !important;
  height: 0 !important;
}

.annotationLayer .linkAnnotation > a:hover, .annotationLayer .buttonWidgetAnnotation.pushButton > a:hover {
  background: none !important;
  box-shadow: none !important;
}

.react-pdf__Page {
  margin-bottom: 0 !important;
}

.mobile__pdf__container #viewerContainer {
  top: 0 !important;
}

.mobile__pdf__container__header {
  visibility: hidden;
}

.mobile__pdf__container footer {
  visibility: hidden;
}

.mobile__pdf__container #viewerContainer .pdfViewer {
  overflow-y: hidden !important;
}
@media only screen and (max-width: 560px) {
  .mobilePdfFormButton{
    margin-top: 15px!important;
  }
  .mobileDownloadHandler{
    z-index: 1;
    position: fixed;
    top: 1%;
    right: 3%;
  }
}

.contactListOverflow{
    text-overflow: ellipsis;
    word-break: break-all;
    /* overflow:hidden; */
    overflow:visible;
    height:auto;
    /* white-space: nowrap; */
    white-space: normal;
}

.pageNumbers{
    list-style: none;
    display: flex;
    margin: 5px 0px 5px;
    padding: 0px;
}

.pageNumbers li {
    padding: 10px;
    border: 1px solid white;
    cursor: pointer;
    font-size: 14px;
    color: #848484;
  }

  
  .pageNumbers li.active {
    background-color: white;
    color: black;
    padding: 10px;
    font-size: 14px;
    align-items: center;
    display: flex;
  }

body {
  background-color: #f6f9fb;
  width: 100%;
}

html {
  font-family: 'Inter', sans-serif;
}
@supports (font-variation-settings: normal) {
  html {
    font-family: 'Inter var', sans-serif;
  }
}

.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.signature p {
  color: #000000;
}

.disclaimer p {
  color: #848484;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* width */

.emailList-ul::-webkit-scrollbar {
  width: 6px;
}

/* Track */

.emailList-ul::-webkit-scrollbar-track {
  background: #e5e5e5;
}

/* Handle */

.emailList-ul::-webkit-scrollbar-thumb {
  background: #dadada;
}

.email-list-selected > div {
  background: #dad6ff;
}

/* Handle on hover */

.react-pdf__Page {
  margin-bottom: 10px;
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-down {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(20px);
  }
}

