body {
  background-color: #f5f5f5;
  margin: 0px;
}


body.campaign {
  height: 100%;
}

@font-face {
  font-family: 'Poppins';
  src: url('/assets/fonts/themes-icon.eot');
  src: url('/assets/fonts/themes-icon.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/themes-icon.woff') format('woff'),
    url('/assets/fonts/themes-icon.ttf') format('truetype'),
    url('/assets/fonts/themes-icon.svg#themes-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-bold';
  src: url("/assets/fonts/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-semibold';
  src: url("/assets/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-medium';
  src: url("/assets/fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

html, 
body {
  font-family: 'Poppins' !important;
}


body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
textarea {
  font-family: 'Poppins';
  color: #6351ed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

::placeholder {
  color: #6351ed;
  font-family: 'Poppins';
}
::-webkit-scrollbar {
  width: 10px;
}
*:focus {
  outline: 0px;
}
::-webkit-scrollbar-thumb {
  background: #49484a !important;
  border-radius: 10px !important;
}
::-webkit-scrollbar-track {
  border-radius: 10px !important;
  background: #bcb8be !important;
}

.ReactModal__Overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  overflow-y: auto;
}

.slick-arrow::before {
  font-family: 'fideate-v2' !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-arrow.slick-disabled {
  opacity: 0.5;
}

.slick-arrow.slick-prev::before {
  content: 'U' !important;
}
.slick-arrow.slick-next::before {
  content: 'V' !important;
}
.slick-slide img {
  display: inline !important;
}

.Popover-tipShape {
  fill: #fff;
  stroke: #cacaca;
}

.article-style {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
