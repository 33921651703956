/* @media screen and (min-width: 370px) and (max-width: 380px) {
    .react-pdf__Document canvas {
        margin-left: 33px
    }
} */

@media screen and (min-width: 410px) and (max-width: 415px) {
  .react-pdf__Document canvas {
    margin-left: -36px;
  }
}

@media screen and (width: 360px) {
  .react-pdf__Document canvas {
    margin-left: 13px;
  }
}

@media screen and (width: 320px) {
  .react-pdf__Document canvas {
    margin-left: 52px;
  }
}

.control-btns-container {
  padding: 15px 0px;
}

.react-pdf__Page__canvas {
  display: none !important;
  height: 0 !important;
}

.annotationLayer .linkAnnotation > a:hover, .annotationLayer .buttonWidgetAnnotation.pushButton > a:hover {
  background: none !important;
  box-shadow: none !important;
}

.react-pdf__Page {
  margin-bottom: 0 !important;
}

.mobile__pdf__container #viewerContainer {
  top: 0 !important;
}

.mobile__pdf__container__header {
  visibility: hidden;
}

.mobile__pdf__container footer {
  visibility: hidden;
}

.mobile__pdf__container #viewerContainer .pdfViewer {
  overflow-y: hidden !important;
}
@media only screen and (max-width: 560px) {
  .mobilePdfFormButton{
    margin-top: 15px!important;
  }
  .mobileDownloadHandler{
    z-index: 1;
    position: fixed;
    top: 1%;
    right: 3%;
  }
}
