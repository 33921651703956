.tooltip-div-share-modal {
    position: relative;
    padding-top: 4px;
  }
  
  .tooltip-div-share-modal .tooltip-text-share-modal {
    visibility: hidden;
    width: 84px;
    background-color: #404040;
    color: #fff;
    border-radius: 6px;
    font-size: 8px;
    text-align: left;
    padding: 6px 6px;
    position: absolute;
    z-index: 1;
    left: 18px;
  }
  
  .tooltip-div-share-modal .tooltip-text-share-modal::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent #404040 transparent transparent;
  }

  .tooltip-div-member:hover .tooltip-text-member {
    visibility: visible;
  }

  .tooltip-div-self:hover .tooltip-text-self {
    visibility: visible;
  }