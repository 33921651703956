.contact_list_crm {
    list-style-type: none;
    overflow-y: auto;
    height: 128px;
    padding-left: 30px;
    padding-right: 6px;
    margin-top: 0px;
}

.contact_list_item_crm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: black;
    margin-bottom: 5px;
    padding: 6px 10px;
    width: 482px;
}

.crm_theme_icon {
    width: 25px;
    height: 25px;
    border: 0px;
    background-color: #6351ed;
    color: white;
    margin-right: 5px;
    border-radius: 3px;
}

.crm_icon_image {
    width: 25px;
    height: 25px;
    border-radius: 3px;
}

.crm_btns_div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 36%;
}
  
.tooltip-div-crm-topic .tooltiptext-crm-topic {
visibility: hidden;
width: auto;
background-color: black;
color: #fff;
text-align: center;
border-radius: 2px;
position: absolute;
right: -18px;
padding: 2px 4px;
font-size: 9px;
z-index: 1;
}

.tooltip-div-crm-topic:hover .tooltiptext-crm-topic {
visibility: visible;
}