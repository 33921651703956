.flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.vertical-separator {
    height: 10.1rem;
    width: 0;
    border: 0.1rem solid #e4e4e4;
    border-right: 0px;
    background-color: #e4e4e4;
    margin-top: 5px;
}

.client-icon-div {
    background-color: #6351ed;
    height: 5rem;
    position: absolute;
    width: 4.5rem;
    padding: 1.4rem;
    border-radius: 14px;
}
.client-icon-div.my-client-icon-div {  
    bottom: 2rem;
}

.client-icon-div.client-results-icon-div {
    bottom: 2rem;
}

.my-client-icon-wrapper {
    cursor: pointer;
}

.relative-div {
    height: 100%;
    position: relative;
}

.my-client-icon-wrapper.client-results h6 {
    position: absolute;
    top: -2.9rem;
}

.my-client-icon-wrapper.invite-clients h6 {
    position: absolute;
    top: -2.1rem;
}

.invite-pstv {
    width: 46%;
    height: 100%;
    margin: auto;
    margin-top: 8.3rem;
}

.invite-clients {
    left: 34%;
    width: 75px;
}

.pstv-score {
    left: 34%;
    width: 75px;
}

.invite {
    left: 78px;
    width: 35%;
}

.pstv {
    right: 83px;
    width: 39%;
}

.footer {
    margin-top: -1.5rem;
}

.footer img{
    left: -25%;
}

.footer h6 {
    color: #adadad;
}

.more-clients {
    position: absolute;
    font-size: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 200px;
    margin-left: -27%;
}

.more-clients p {
    color: #7dc5d0;
    margin: 0;
}